import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action, combineReducers } from 'redux';

import { mapObject } from '../reducers/mapObject';
import mapDecoration from './mapDecoration';
import { question } from '../reducers/question';
import { file } from '../reducers/file';
import { map } from '../reducers/map';
import { route } from '../reducers/route';
import category from './category';
import user from './user';
import { language } from '../reducers/language';
import mapFilter from './mapFilter';
import adminArea from './adminArea';
import account from './account';
import userData from './userData';

export const rootReducer = combineReducers({
  account,
  mapObject,
  mapDecoration,
  question,
  file,
  map,
  route,
  category,
  language,
  mapFilter,
  user,
  adminArea,
  userData,
});
export type AppState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>;

export default store;
