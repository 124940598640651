import axios from 'axios';
import { MapDecoration, MapDecorationDto } from 'store/mapDecoration';
import { Category } from './store/category';
import {
  PasswordChange,
  User,
  UserCreateDto,
  UserUpdateDto,
} from './store/user';

const baseUrl = process.env.REACT_APP_API_URL;

const api = {
  category(url = baseUrl + 'categories') {
    return {
      fetchCategories: () => axios.get<Category[]>(url),
      fetchCategory: (id: number | string) =>
        axios.get<Category>(`${url}/${id}`),
      createCategory: (entity: Category) => axios.post<Category>(url, entity),
      updateCategory: (id: number | string, entity: Partial<Category>) =>
        axios.patch<Category>(`${url}/${id}`, entity),
      deleteCategory: (id: number | string) =>
        axios.delete<Category>(`${url}/${id}`),
    };
  },
  decoration(url = baseUrl + 'decorations') {
    return {
      fetchDecorations: () => axios.get<MapDecoration[]>(url),
      fetchDecoration: (id: number | string) =>
        axios.get<MapDecoration>(`${url}/${id}`),
      createDecoration: (entity: MapDecorationDto) =>
        axios.post<MapDecoration>(url, entity),
      updateDecoration: (id: number | string, entity: Partial<MapDecoration>) =>
        axios.patch<MapDecoration>(`${url}/${id}`, entity),
      deleteDecoration: (id: number | string) =>
        axios.delete<MapDecoration>(`${url}/${id}`),
    };
  },
  user(url = baseUrl + 'users') {
    return {
      fetchUsers: () => axios.get<User[]>(url),
      fetchUser: (id: number | string) => axios.get<User>(`${url}/${id}`),
      createUser: (entity: UserCreateDto) => axios.post<User>(url, entity),
      updateUser: (id: number | string, entity: Partial<UserUpdateDto>) =>
        axios.patch<User>(`${url}/${id}`, { ...entity, id: Number(id) }),
      deleteUser: (id: number | string) => axios.delete<User>(`${url}/${id}`),
      changePassword: (entity: Partial<PasswordChange>) =>
        axios.post<User>(`${url}/changePassword`, entity),
    };
  },
  auth: (url = baseUrl + 'auth') => ({
    login: (email: string, password: string) =>
      axios.post<{ access_token: string }>(`${url}/login`, {
        username: email,
        password,
      }),
  }),
  locale() {
    return {
      fetchLocale: (code: string) =>
        fetch(
          process.env.REACT_APP_LOCALE_URL + code + '.json?t=' + Date.now()
        ).then(
          (v) =>
            v.json() as Promise<{
              [namespace: string]: { [key: string]: string };
            }>
        ),
      saveLocale: (
        code: string,
        data: { [namespace: string]: { [key: string]: string } }
      ) => axios.put(baseUrl + 'locale/' + code, data),
    };
  },
};
export default api;
