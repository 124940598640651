import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OverlayType =
  | 'routes'
  | 'addRoute'
  | 'editRoute'
  | 'mapObjects'
  | 'addMapObject'
  | 'editMapObject'
  | 'mapDecorations'
  | 'addMapDecoration'
  | 'editMapDecoration'
  | 'categories'
  | 'addCategory'
  | 'editCategory'
  | 'game'
  | 'addQuestion'
  | 'editQuestion'
  | 'languages'
  | 'settings'
  | 'addLanguage'
  | 'editLanguage'
  | 'addUser'
  | 'editUser';

const slice = createSlice({
  name: 'admin_area',
  initialState: {
    activeOverlay: undefined as { type: OverlayType; props?: any } | undefined,
  },
  reducers: {
    setActiveOverlay: (
      state,
      action: PayloadAction<{ type: OverlayType; props?: any }>
    ) => {
      state.activeOverlay = action.payload;
    },
    clearActiveOverlay: (state) => {
      state.activeOverlay = undefined;
    },
  },
});

export default slice.reducer;
export const adminAreaActions = slice.actions;
