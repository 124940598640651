import api from './api';
import imageCompression from 'browser-image-compression';

export const ACTION_TYPES = {
  FETCH_FILES: 'FETCH_FILES',
  FETCH_FILE: 'FETCH_FILE',
  CREATE_FILE: 'CREATE_FILE',
  UPDATE_FILE: 'UPDATE_FILE',
  DELETE_FILE: 'DELETE_FILE',
  RESET_FILE: 'RESET_FILE',
};

export const fetchFile = (fileName, onSuccess) => (dispatch) => {
  api
    .file()
    .fetchFile(fileName)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_FILE,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const createFile = (data, onSuccess) => async (dispatch) => {
  let formData = new FormData();
  for (let i = 0; i < data.length; i++) {
    //var maxWidthOrHeight = data[i].type === 'image' ? 600 : 50
    var renamedFile = '';
    if (data[i].type === 'icon')
      renamedFile = await compressImage(data[i].file, 80, data[i].uuid);
    else if (data[i].type === 'thumbnail') {
      renamedFile = await compressImage(data[i].file, 360, data[i].uuid);
    } else renamedFile = renameFile(data[i].file, data[i].uuid); //await compressImage(data[i].file, maxWidthOrHeight, data[i].uuid)
    formData.append('files', renamedFile);
  }

  api
    .file()
    .createFile(formData)
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
async function compressImage(file, maxWidthOrHeight, uuid) {
  const options = {
    maxSizeMB: 5,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    console.log(
      'compressedFile instanceof Blob',
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 5120 / 5120} MB`); // smaller than maxSizeMB

    return renameFile(compressedFile, uuid);
  } catch (error) {
    console.error(error);
  }
}

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}
