import api from './api';

export const ACTION_TYPES = {
  FETCH_QUESTIONS: 'FETCH_QUESTIONS',
  FETCH_QUESTION: 'FETCH_QUESTION',
  CREATE_QUESTION: 'CREATE_QUESTION',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',
  RANDOM_QUESTIONS: 'RANDOM_QUESTIONS',
  SET_ACTIVE: 'SET_ACTIVE',
};

export const fetchQuestions = () => (dispatch) => {
  api
    .question()
    .fetchQuestions()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_QUESTIONS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const createQuestion = (data, onSuccess) => (dispatch) => {
  api
    .question()
    .createQuestion(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.CREATE_QUESTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const updateQuestion = (data, onSuccess) => (dispatch) => {
  api
    .question()
    .updateQuestion(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_QUESTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const deleteQuestion = (id, onSuccess) => (dispatch) => {
  api
    .question()
    .deleteQuestion(id)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.DELETE_QUESTION,
        payload: id,
      });

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const randomQuestions = (questions) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.RANDOM_QUESTIONS,
    payload: questions,
  });
};
export const setActiveQuestion = (question = null) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ACTIVE,
    payload: question,
  });
};
