import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import './interceptors';
import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister as unregisterServiceWorker } from './serviceWorkerRegistration';

import store from './store';
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// Learn more about service workers: http://bit.ly/CRA-PWA
unregisterServiceWorker();
