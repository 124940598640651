import api from './api';

export const ACTION_TYPES = {
  UPLOAD: 'UPLOAD',
  FETCH_OBJECTS: 'FETCH_OBJECTS',
  FETCH_OBJECT: 'FETCH_OBJECT',
  CREATE_OBJECT: 'CREATE_OBJECT',
  UPDATE_OBJECT: 'UPDATE_OBJECT',
  DELETE_OBJECT: 'DELETE_OBJECT',

  FETCH_FILE: 'FETCH_FILE',
};

export const fetchMapObjects = (onSuccess) => (dispatch) => {
  api
    .object()
    .fetchObjects()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_OBJECTS,
        payload: response.data,
      });
      if (typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};

export const createMapObject = (data, onSuccess) => (dispatch) => {
  api
    .object()
    .createObject(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.CREATE_OBJECT,
        payload: response.data,
      });
      onSuccess(response.data);
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const updateMapObject = (data, onSuccess) => (dispatch) => {
  api
    .object()
    .updateObject(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_OBJECT,
        payload: response.data,
      });
      onSuccess(response.data);
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const deleteMapObject = (id, onSuccess) => (dispatch) => {
  api
    .object()
    .deleteObject(id)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.DELETE_OBJECT,
        payload: id,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
