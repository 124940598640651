import api from './api';

export const ACTION_TYPES = {
  FETCH_LANGUAGES: 'FETCH_LANGUAGES',
  FETCH_LANGUAGE: 'FETCH_LANGUAGE',
  CREATE_LANGUAGE: 'CREATE_LANGUAGE',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  DELETE_LANGUAGE: 'DELETE_LANGUAGE',
  SELECT_LANGUAGE: 'SELECT_LANGUAGE'
};

export const fetchLanguages = (onSuccess) => (dispatch) => {
  api
    .language()
    .fetchLanguages()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_LANGUAGES,
        payload: response.data,
      });
      if (typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const createLanguage = (data, onSuccess) => (dispatch) => {
  api
    .language()
    .createLanguage(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.CREATE_LANGUAGE,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const updateLanguage = (data, onSuccess) => (dispatch) => {
  api
    .language()
    .updateLanguage(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_LANGUAGE,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const deleteLanguage = (id, onSuccess) => (dispatch) => {
  api
    .language()
    .deleteLanguage(id)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.DELETE_LANGUAGE,
        payload: id,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const selectDisplayLanguage = (id) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SELECT_LANGUAGE,
    payload: id,
  });
};
