import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MY_ROUTES, MY_ROUTES_LOCALSTORAGE } from 'constants/myRoutes';
import { MyRoute } from 'types/myRoute';
import { accountThunkActions } from './account';

type InitialState = {
  favoriteObjectIds: { [id: number]: boolean };
  favoritesLocked: boolean;
  displayHiddenItems: boolean;
  location?: { lat: number; lng: number };
  myRoutes: MyRoute[];
  activeMyRoute: string;
};
const slice = createSlice({
  name: 'userData',
  initialState: {
    favoriteObjectIds: JSON.parse(
      localStorage.getItem('userData_favoriteObjectIds') ?? '{}'
    ),
    displayHiddenItems:
      localStorage.getItem('userData_displayHiddenItems') === 'true',
    location: undefined,
    favoritesLocked: false,
    myRoutes: [],
    activeMyRoute: '',
  } as InitialState,
  reducers: {
    addObjectToMyRoute: (state, { payload }: PayloadAction<MyRoute>) => {
      state.myRoutes = [
        ...state.myRoutes.map((x) =>
          x.name === payload.name
            ? { ...x, objects: { ...x.objects, ...payload.objects } }
            : x
        ),
      ];
      localStorage.setItem(
        MY_ROUTES_LOCALSTORAGE,
        JSON.stringify(state.myRoutes)
      );
    },
    removeObjectFromMyRoute: (state, { payload }: PayloadAction<MyRoute>) => {
      state.myRoutes = [
        ...state.myRoutes.map((x) => {
          if (x.name === payload.name) {
            delete x.objects[Number(Object.keys(payload.objects)[0])];
          }
          return x;
        }),
      ];

      const tempObjects = { ...state.favoriteObjectIds };
      Object.keys(state.favoriteObjectIds).forEach(() => {
        const id = Number(Object.keys(payload.objects)[0]);
        if (tempObjects[id]) {
          delete tempObjects[id];
        }
      });
      state.favoriteObjectIds = tempObjects;

      localStorage.setItem(
        MY_ROUTES_LOCALSTORAGE,
        JSON.stringify(state.myRoutes)
      );
    },
    clearMyRoute: (state) => {
      state.myRoutes = [
        ...state.myRoutes.map((x) =>
          x.name === state.activeMyRoute ? { ...x, objects: {} } : x
        ),
      ];
      state.favoriteObjectIds = {};

      localStorage.setItem(
        MY_ROUTES_LOCALSTORAGE,
        JSON.stringify(state.myRoutes)
      );
    },
    addMyRoute: (state, { payload }: PayloadAction<MyRoute>) => {
      state.myRoutes = [...state.myRoutes, payload];
      localStorage.setItem(
        MY_ROUTES_LOCALSTORAGE,
        JSON.stringify(state.myRoutes)
      );
    },
    deleteMyRoute: (state, { payload }: PayloadAction<string>) => {
      state.myRoutes = state.myRoutes.filter((x) => x.name !== payload);
      localStorage.setItem(
        MY_ROUTES_LOCALSTORAGE,
        JSON.stringify(state.myRoutes)
      );
    },
    setActiveMyRoute: (state, { payload }: PayloadAction<string>) => {
      state.activeMyRoute = payload;
      state.favoriteObjectIds =
        state.myRoutes.find((x) => x.name === payload)?.objects || {};
    },
    loadMyRoutes: (state) => {
      let oldRoute = [] as MyRoute[];
      const localMyRoutes = JSON.parse(
        localStorage.getItem(MY_ROUTES_LOCALSTORAGE) || '[]'
      ) as MyRoute[];
      if (state.favoriteObjectIds) {
        oldRoute = !localMyRoutes.some((x) => x.name === MY_ROUTES)
          ? [
              {
                name: MY_ROUTES,
                objects: JSON.parse(
                  localStorage.getItem('userData_favoriteObjectIds') || '{}'
                ),
              },
            ]
          : oldRoute;
      }
      state.myRoutes = [...localMyRoutes, ...oldRoute];
    },
    loadFavorites: (
      state,
      { payload }: PayloadAction<{ [id: number]: boolean }>
    ) => {
      state.favoriteObjectIds = payload;
      state.favoritesLocked = true;
    },
    loadLocalFavorites: (state, { payload }: PayloadAction<MyRoute>) => {
      state.favoriteObjectIds = payload.objects;
      state.favoritesLocked = false;
    },
    setFavoritesLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.favoritesLocked = payload;
    },
    toggleFavorite: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      if (!state.favoriteObjectIds[id]) {
        state.favoriteObjectIds[id] = true;
      } else {
        delete state.favoriteObjectIds[id];
      }

      localStorage.setItem(
        'userData_favoriteObjectIds',
        JSON.stringify(state.favoriteObjectIds)
      );
    },
    changeLocation: (
      state,
      { payload }: PayloadAction<{ lat: number; lng: number } | undefined>
    ) => {
      state.location = payload;
    },
    toggleItemVisibility: (state) => {
      state.displayHiddenItems = !state.displayHiddenItems;

      localStorage.setItem(
        'userData_displayHiddenItems',
        `${state.displayHiddenItems}`
      );
    },
  },

  // Hide hidden items on logout.
  extraReducers: (builder) => {
    builder.addCase(accountThunkActions.logout.fulfilled, (state) => {
      state.displayHiddenItems = false;

      localStorage.setItem(
        'userData_displayHiddenItems',
        `${state.displayHiddenItems}`
      );
    });
  },
});

export default slice.reducer;
export const userDataActions = slice.actions;
