import { Dialog } from '@material-ui/core';
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
} from 'react';

export type Dialogs = { [key: string]: React.ReactElement };
export type SetDialogs = React.Dispatch<React.SetStateAction<Dialogs>>;

const DialogContext = createContext<
  { dialogs: Dialogs; setDialogs: SetDialogs } | undefined
>(undefined);
export default DialogContext;

export function DialogProvider(props: PropsWithChildren<{}>) {
  const [dialogs, setDialogs] = useState<Dialogs>({});

  const handleClose = (key: string) => {
    delete dialogs[key];
    setDialogs({ ...dialogs });
  };

  return (
    <DialogContext.Provider value={{ dialogs, setDialogs }}>
      {props.children}
      {Object.keys(dialogs).map((k) => (
        <Dialog
          open={true}
          key={k}
          maxWidth="xl"
          onClose={() => handleClose(k)}
        >
          {React.cloneElement(dialogs[k], { dismiss: () => handleClose(k) })}
        </Dialog>
      ))}
    </DialogContext.Provider>
  );
}

export const useDialog = () => useContext(DialogContext);
