import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import api from '../api';
import { parseJwt } from '../utils/jwt.util';

const getInitialState = () => {
  const token = localStorage.getItem('access_token');

  if (!token) {
    return {
      access_token: undefined,
      claims: undefined,
    };
  }

  return {
    access_token: token as string | undefined,
    claims: parseJwt(token) as Claims | undefined,
  };
};

export const accountThunkActions = {
  login: createAsyncThunk(
    'account/login',
    async (payload: { email: string; password: string }) => {
      const { data } = await api.auth().login(payload.email, payload.password);

      localStorage.setItem('access_token', data.access_token);

      return {
        access_token: data.access_token,
        claims: parseJwt(data.access_token) as Claims,
      };
    }
  ),
  logout: createAsyncThunk('account/logout', async () => {
    localStorage.removeItem('access_token');
  }),
};

interface Claims {
  /** User Id */
  sub: number;

  /** Email */
  email: string;

  /** Full Name */
  name: string;

  /** Space separated permissions (edit, manage_users) */
  scope: string;

  /** Issued At */
  iat: number;

  /** Expires At */
  exp: number;
}

const slice = createSlice({
  name: 'category',
  initialState: {
    ...getInitialState(),

    status: 'idle' as 'loading' | 'idle',
    error: undefined as SerializedError | undefined,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(accountThunkActions.login.pending, (state) => {
        state.access_token = undefined;
        state.error = undefined;
        state.status = 'loading';
      })
      .addCase(accountThunkActions.login.fulfilled, (state, { payload }) => {
        state.access_token = payload.access_token;
        state.claims = payload.claims;
        state.status = 'idle';
      })
      .addCase(accountThunkActions.login.rejected, (state, { error }) => {
        state.status = 'idle';
        state.error = error;
      })

      .addCase(accountThunkActions.logout.pending, (state) => {
        state.access_token = undefined;
        state.error = undefined;
        state.status = 'loading';
      })
      .addCase(accountThunkActions.logout.fulfilled, (state) => {
        state.access_token = undefined;
        state.claims = undefined;
        state.status = 'idle';
      })
      .addCase(accountThunkActions.logout.rejected, (state, { error }) => {
        state.status = 'idle';
        state.error = error;
      }),
});

export default slice.reducer;
export const accountActions = slice.actions;
