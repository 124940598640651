import i18n from 'i18next';
import HttpBackend, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import a from './assets/images/1.png'

const backendOptions: BackendOptions = {
  loadPath: process.env.REACT_APP_LOCALE_URL + '{{lng}}.json',
  parse: (data, language, namespace) => {
    // For future reference, put namespaces in different files.
    return JSON.parse(data)[namespace as string];
  },
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: backendOptions,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
