import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

function getFileHeader() {
  return { 'Content-Type': 'multipart/form-data' };
}

export default {
  object(url = baseUrl + 'objects/') {
    return {
      fetchObjects: () => axios.get(url),
      createObject: (newObject) => axios.post(url, newObject),
      updateObject: (editedObject) => axios.put(url, editedObject),
      deleteObject: (id) => axios.delete(url + id),
    };
  },
  question(url = baseUrl + 'questions/') {
    return {
      fetchQuestions: () => axios.get(url),
      createQuestion: (newQuestion) => axios.post(url, newQuestion),
      updateQuestion: (editedQuestion) => axios.put(url, editedQuestion),
      deleteQuestion: (id) => axios.delete(url + id),
    };
  },
  file(url = baseUrl + 'files/') {
    return {
      fetchFile: (fileName) => axios.get(url + fileName),
      createFile: (newFile) =>
        axios.post(url, newFile, { headers: getFileHeader() }),
    };
  },
  route(url = baseUrl + 'routes/') {
    return {
      fetchRoutes: () => axios.get(url),
      createRoute: (newRoute) => axios.post(url, newRoute),
      updateRoute: (editedRoute) => axios.put(url, editedRoute),
      deleteRoute: (id) => axios.delete(url + id),
      setDefaultRoute: (defaultRoute) => axios.patch(url + defaultRoute.id, defaultRoute)
    };
  },
  language(url = baseUrl + 'languages/') {
    return {
      fetchLanguages: () => axios.get(url),
      createLanguage: (newLanguage) => axios.post(url, newLanguage),
      updateLanguage: (editedLanguage) => axios.put(url, editedLanguage),
      deleteLanguage: (id) => axios.delete(url + id),
    };
  },
};
