// From https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
export function getDistanceFromLatLonInKm(lat1: number, lon1: number, latLng: {lat: number, lng: number}) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(latLng.lat - lat1); // deg2rad below
  var dLon = deg2rad(latLng.lng - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(latLng.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 1000; // Distance in m
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

