import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import api from '../api';
import { ApiError } from './errorPayload';

export type UserCreateDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  type: 0 | 1 | 2;
};

export type UserUpdateDto = {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  type: 0 | 1 | 2;
};

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: 0 | 1 | 2;
}

export interface PasswordChange {
  oldPassword: string;
  newPassword: string;
  newPasswordRetyped: string;
}

export const userThunkActions = {
  fetchAll: createAsyncThunk('user/fetchAll', async () => {
    const { data } = await api.user().fetchUsers();
    return data;
  }),

  fetchOne: createAsyncThunk('user/fetchOne', async (id: string | number) => {
    const { data } = await api.user().fetchUser(id);
    return data;
  }),

  create: createAsyncThunk<
    User,
    UserCreateDto,
    {
      rejectValue: ApiError;
    }
  >('user/create', async (user, { rejectWithValue }) => {
    try {
      const { data } = await api.user().createUser(user);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }),

  update: createAsyncThunk<
    User,
    { id: string | number; user: UserUpdateDto },
    {
      rejectValue: ApiError;
    }
  >('user/update', async ({ id, user }, { rejectWithValue }) => {
    // Password was not provided.
    if (user.password === '') {
      delete user.password;
    }

    try {
      const { data } = await api.user().updateUser(id, user);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }),

  delete: createAsyncThunk('user/delete', async (id: string | number) => {
    const { data } = await api.user().deleteUser(id);
    return data;
  }),

  changePassword: createAsyncThunk(
    'user/changePassword',
    async (passwordChange: PasswordChange, { rejectWithValue }) => {
      try {
        const { data } = await api.user().changePassword(passwordChange);
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  ),
};

const adapter = createEntityAdapter<User>();

const slice = createSlice({
  name: 'user',
  initialState: adapter.getInitialState({ status: 'idle' } as {
    status: 'loading' | 'idle';
    lastSyncTimestamp?: number;
    error?: { message: string[]; statusCode: number };
  }),
  reducers: {
    resetError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userThunkActions.fetchAll.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(userThunkActions.fetchAll.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(userThunkActions.fetchAll.rejected, (state) => {
        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(userThunkActions.fetchOne.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(userThunkActions.fetchOne.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, payload);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(userThunkActions.fetchOne.rejected, (state) => {
        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(userThunkActions.create.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(userThunkActions.create.fulfilled, (state, { payload }) => {
        adapter.addOne(state, payload);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(userThunkActions.create.rejected, (state, { payload }) => {
        if (payload) {
          if (typeof payload.message === 'string') {
            state.error = { ...payload, message: [payload.message] };
          } else {
            state.error = { ...payload, message: payload.message };
          }
        } else {
          state.error = payload;
        }

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(userThunkActions.update.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(userThunkActions.update.fulfilled, (state, { payload }) => {
        adapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(userThunkActions.update.rejected, (state, { payload }) => {
        if (payload) {
          if (typeof payload.message === 'string') {
            state.error = { ...payload, message: [payload.message] };
          } else {
            state.error = { ...payload, message: payload.message };
          }
        } else {
          state.error = payload;
        }

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(userThunkActions.delete.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(userThunkActions.delete.fulfilled, (state, { payload }) => {
        adapter.removeOne(state, payload.id);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(userThunkActions.delete.rejected, (state) => {
        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(userThunkActions.changePassword.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        userThunkActions.changePassword.fulfilled,
        (state, { payload }) => {
          adapter.addOne(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(userThunkActions.changePassword.rejected, (state, response) => {
        const payload = response.payload as ApiError;
        if (payload) {
          if (typeof payload.message === 'string') {
            state.error = { ...payload, message: [payload.message] };
          } else {
            state.error = { ...payload, message: payload.message };
          }
        } else {
          state.error = payload;
        }

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      });
  },
});

export default slice.reducer;
export const userActions = slice.actions;
