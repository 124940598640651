import { ACTION_TYPES } from '../actions/questions';
import { AppFile } from './file';
import { MapObject } from './mapObject';
import { TextField } from './textField';

export interface Question {
  id: number;
  textFields: TextField[];
  mapObject: MapObject;
  files: AppFile[];
}

const initialState = {
  list: [] as Question[],
  minigameQuestions: [] as Question[],
  activeQuestion: null as Question | null,
};

export const question = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_QUESTIONS:
      return {
        ...state,
        list: [...action.payload],
      };
    case ACTION_TYPES.RANDOM_QUESTIONS:
      return {
        ...state,
        minigameQuestions: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE:
      return {
        ...state,
        activeQuestion: action.payload,
      };
    // case ACTION_TYPES.FETCH_QUESTION:
    //   return {
    //     ...state,
    //     question: action.payload,
    //   };
    case ACTION_TYPES.CREATE_QUESTION:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case ACTION_TYPES.UPDATE_QUESTION:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id == action.payload.id ? action.payload : x
        ),
      };
    case ACTION_TYPES.DELETE_QUESTION:
      return {
        ...state,
        list: state.list.filter((x) => x.id != action.payload),
      };
    default:
      return state;
  }
};
