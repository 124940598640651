import { ACTION_TYPES } from '../actions/files';

export interface AppFile {
  id: number;
  name: string;
  UUID: string;
  fileType: string;
  metadata: {
    author: string;
    altText: string;
    text: string;
    uploadDate: string;
    name: string;
  };
}

const initialState = {
  list: [] as AppFile[],
  file: null,
};

export const file = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_FILES:
      return {
        ...state,
        list: [...action.payload],
      };
    case ACTION_TYPES.FETCH_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case ACTION_TYPES.CREATE_FILE:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case ACTION_TYPES.DELETE_FILE:
      return {
        ...state,
        list: state.list.filter((x) => x.id != action.payload),
      };
    default:
      return state;
  }
};
