import { ACTION_TYPES } from '../actions/routes';
import { Route } from '../store/route';

const initialState = {
  list: [] as Route[],
  defaultRoute: undefined as Route | undefined,
};

export const route = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ROUTES:
      return {
        ...state,
        list: [...(action.payload as Route[])],
      };
    case ACTION_TYPES.SET_DEFAULT_ROUTE:
      let defaultRoute = action.payload;
      if (!defaultRoute) {
        state.list.forEach((route) => {
          if (route.isDefault) defaultRoute = route;
        });
      }
      if (!defaultRoute) defaultRoute = state.list[0];    // If there is no default route
      return {
        ...state,
        defaultRoute: defaultRoute,
      };
    case ACTION_TYPES.CREATE_ROUTE:
      return {
        ...state,
        list: [...state.list, action.payload as Route],
      };
    case ACTION_TYPES.UPDATE_ROUTE:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id == action.payload.id ? (action.payload as Route) : x
        ),
      };
    case ACTION_TYPES.DELETE_ROUTE:
      return {
        ...state,
        list: state.list.filter((x) => x.id != action.payload),
      };
    default:
      return state;
  }
};
