import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import api from '../api';
import { TextField } from '../reducers/textField';

export interface Category {
  id: number;
  textFields: TextField[];
}

export const categoryThunkActions = {
  fetchAll: createAsyncThunk('category/fetchAll', async () => {
    const { data } = await api.category().fetchCategories();
    return data;
  }),

  fetchOne: createAsyncThunk(
    'category/fetchOne',
    async (id: string | number) => {
      const { data } = await api.category().fetchCategory(id);
      return data;
    }
  ),

  create: createAsyncThunk('category/create', async (category: Category) => {
    const { data } = await api.category().createCategory(category);
    return data;
  }),

  update: createAsyncThunk(
    'category/update',
    async ({
      id,
      category,
    }: {
      id: string | number;
      category: Partial<Category>;
    }) => {
      const { data } = await api.category().updateCategory(id, category);
      return data;
    }
  ),

  delete: createAsyncThunk('category/delete', async (id: string | number) => {
    const { data } = await api.category().deleteCategory(id);
    return data;
  }),
};

const adapter = createEntityAdapter<Category>();

const slice = createSlice({
  name: 'category',
  initialState: adapter.getInitialState({ status: 'idle' } as {
    status: 'loading' | 'idle';
    lastSyncTimestamp?: number;
    error?: SerializedError;
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoryThunkActions.fetchAll.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        categoryThunkActions.fetchAll.fulfilled,
        (state, { payload }) => {
          adapter.setAll(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(categoryThunkActions.fetchAll.rejected, (state, { error }) => {
        state.error = error;

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(categoryThunkActions.fetchOne.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        categoryThunkActions.fetchOne.fulfilled,
        (state, { payload }) => {
          adapter.upsertOne(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(categoryThunkActions.fetchOne.rejected, (state, { error }) => {
        state.error = error;

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(categoryThunkActions.create.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(categoryThunkActions.create.fulfilled, (state, { payload }) => {
        adapter.addOne(state, payload);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(categoryThunkActions.create.rejected, (state, { error }) => {
        state.error = error;

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(categoryThunkActions.update.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(categoryThunkActions.update.fulfilled, (state, { payload }) => {
        adapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(categoryThunkActions.update.rejected, (state, { error }) => {
        state.error = error;

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })

      .addCase(categoryThunkActions.delete.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(categoryThunkActions.delete.fulfilled, (state, { payload }) => {
        adapter.removeOne(state, payload.id);

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      })
      .addCase(categoryThunkActions.delete.rejected, (state, { error }) => {
        state.error = error;

        state.status = 'idle';
        state.lastSyncTimestamp = Date.now();
      });
  },
});

export default slice.reducer;
export const categoryActions = slice.actions;
