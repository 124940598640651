import { Route } from 'store/route';
import { ACTION_TYPES } from '../actions/mapObject';
import { Category } from '../store/category';
import { AppFile } from './file';
import { Icon } from './icon';
import { TextField } from './textField';

export interface MapObject {
  id: number;
  address: string;
  /**
   * Formatted as "lat,lon";
   */
  coordinates: string;
  facebook: string;
  instagram: string;
  webPage: string;
  phoneNumber: string;
  defaultIconId: number;
  icons: Icon[];
  categories: Category[];
  files: AppFile[];
  textFields: TextField[];
  route: Route;
  routeId: number;
  isPublic: boolean;
  range: number;
}

const initialState = {
  list: [] as MapObject[],
};

export const mapObject = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_OBJECTS:
      return {
        ...state,
        list: [...(action.payload as MapObject[])],
      };
    // case ACTION_TYPES.FETCH_OBJECT:
    //   return {
    //     ...state,
    //     mapObject: action.payload as MapObject,
    //   };
    case ACTION_TYPES.CREATE_OBJECT:
      return {
        ...state,
        list: [...state.list, action.payload as MapObject],
      };
    case ACTION_TYPES.UPDATE_OBJECT:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id == action.payload.id ? (action.payload as MapObject) : x
        ),
      };
    case ACTION_TYPES.DELETE_OBJECT:
      return {
        ...state,
        list: state.list.filter((x) => x.id != action.payload),
      };
    default:
      return state;
  }
};
