import api from './api';

export const ACTION_TYPES = {
  UPLOAD: 'UPLOAD',
  FETCH_ROUTES: 'FETCH_ROUTES',
  FETCH_ROUTE: 'FETCH_ROUTE',
  CREATE_ROUTE: 'CREATE_ROUTE',
  UPDATE_ROUTE: 'UPDATE_ROUTE',
  DELETE_ROUTE: 'DELETE_ROUTE',
  SET_DEFAULT_ROUTE: 'SET_DEFAULT_ROUTE',
};

export const fetchRoutes = (onSuccess) => (dispatch) => {
  api
    .route()
    .fetchRoutes()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ROUTES,
        payload: response.data,
      });
      if (typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};

export const createRoute = (data, onSuccess) => (dispatch) => {
  api
    .route()
    .createRoute(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.CREATE_ROUTE,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};
export const updateRoute = (data, onSuccess, onError) => (dispatch) => {
  api
    .route()
    .updateRoute(data)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_ROUTE,
        payload: response.data,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      onError(error.response.data.message)
      console.error(error);
    });
};
export const deleteRoute = (id, onSuccess) => (dispatch) => {
  api
    .route()
    .deleteRoute(id)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.DELETE_ROUTE,
        payload: id,
      });
      onSuccess();
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR',
        payload: error.response,
      });
      console.error(error);
    });
};

export const setDefaultRoute = (route = undefined, onSuccess = () => {}) => (
  dispatch
) => {
  if (route) {
    api
      .route()
      .setDefaultRoute(route)
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.FETCH_ROUTES,
          payload: response.data,
        });
        dispatch({
          type: ACTION_TYPES.SET_DEFAULT_ROUTE,
          payload: route,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.response,
        });
        console.error(error);
      });
  } else {
    // If route is undefined, setDefaultRoute was called just to change state.defaultRoute to default route from state.list
    dispatch({
      type: ACTION_TYPES.SET_DEFAULT_ROUTE,
      payload: route,
    });
  }
};
