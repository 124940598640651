import { isMobile } from 'react-device-detect';
import { ACTION_TYPES } from '../actions/map';

const initialState = {
  style: {},
  chargingStations: [],
  map: null,
  defaultColors: [],
  clickedLatLng: { lat: 0, lng: 0 },
  myRouteTutorial: false,
  screenInfo: { device: isMobile ? 'mobile' : 'desktop', orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape', innerHeight: window.innerHeight },
  welcome: !localStorage.getItem('tutorialWasOpened'),
  myRouteTutorial: false,
  showDisclamer:
    !localStorage.getItem('showDisclamer') ? true :
    localStorage.getItem('showDisclamer') === 'false'
      ? false
      : true,
};

export const map = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case ACTION_TYPES.SET_STYLE:
      return {
        ...state,
        style: { ...action.payload },
      };
    case ACTION_TYPES.SET_DEFAULT_COLORS:
      return {
        ...state,
        defaultColors: action.payload,
      };
    case ACTION_TYPES.RESET_STYLE:
      var newStyle = { ...state.style };
      return {
        ...state,
        style: newStyle,
      };
    case ACTION_TYPES.SET_SOURCE:
      return {
        ...state,
        style: {
          ...state.style,
          sources: {
            ...state.style.sources,
            [action.payload.name]: action.payload.data,
          },
        },
      };
    case ACTION_TYPES.ADD_LAYER:
      var layers = [...state.style.layers];
      var temp = layers.find((x) => x.id === action.payload.id);
      if (typeof temp === 'undefined') {
        layers = [...layers, action.payload];
      }
      return {
        ...state,
        style: { ...state.style, layers: layers },
      };
    case ACTION_TYPES.REMOVE_LAYER:
      var newStyle = { ...state.style };
      newStyle.layers = state.style.layers.filter(
        (x) => x.id !== action.payload
      );
      return {
        ...state,
        style: newStyle,
      };
    case ACTION_TYPES.SET_RENDERED_OBJECTS:
      return {
        ...state,
        style: {
          ...state.style,
          sources: {
            ...state.style.sources,
            mapObjects: {
              ...state.style.sources.mapObjects,
              data: {
                ...state.style.sources.mapObjects.data,
                features: state.style.sources.mapObjects.data.features.map(
                  (f) => ({
                    ...f,
                    geometry: {
                      ...f.geometry,
                      coordinates: action.payload.objectIds.find(
                        (c) => c === f.properties.mapObjectId
                      )
                        ? action.payload.allObjects
                            .find((o) => o.id === f.properties.mapObjectId)
                            .coordinates.split(',')
                            .reverse()
                        : ['0', '0'],
                    },
                  })
                ),
              },
            },
          },
        },
      };
    case ACTION_TYPES.CHANGE_LATLNG:
      return {
        ...state,
        clickedLatLng: action.payload,
      };
    case ACTION_TYPES.TOGGLE_WELCOME:
      return {
        ...state,
        welcome: action.payload,
      };
    case ACTION_TYPES.TOGGLE_DISCLAMER:
      return {
        ...state,
        showDisclamer: action.payload,
      };
    case ACTION_TYPES.TOGGLE_MY_ROUTE_TUTORIAL:
      return {
        ...state,
        myRouteTutorial: action.payload,
      };
    case ACTION_TYPES.SET_SCREEN_INFO:
      return {
        ...state,
        screenInfo: action.payload,
      };
    default:
      return state;
  }
};
