import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import { DialogProvider } from 'contexts/DialogContext';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <DialogProvider>
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    name="Login Page"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    path="/"
                    name="Map"
                    render={(props) => <TheLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </DialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
