import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#0d7077',
    },
    secondary: {
      main: '#fff',
    },
    warning: {
      main: 'rgb(236, 79, 79)',
    },
    background: { default: '#e4e4e4', paper: '#fff' },
  },
  typography: {
    fontFamily: 'Karma',
  },
});
