import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { Icon } from 'reducers/icon';
import api from '../api';

export interface MapDecoration {
  id: number;
  hintText?: string;
  /**
   * Formatted as "lat,lon";
   */
  coordinates: string;
  icon: Icon;
  isPublic: boolean;
}

export type MapDecorationDto = {
  hintText?: string;
  /**
   * Formatted as "lat,lon";
   */
  coordinates: string;
  icon: Icon | null;
};

export const mapDecorationThunkActions = {
  fetchAll: createAsyncThunk('mapDecoration/fetchAll', async () => {
    const { data } = await api.decoration().fetchDecorations();
    return data;
  }),

  fetchOne: createAsyncThunk(
    'mapDecoration/fetchOne',
    async (id: string | number) => {
      const { data } = await api.decoration().fetchDecoration(id);
      return data;
    }
  ),

  create: createAsyncThunk(
    'mapDecoration/create',
    async (entity: MapDecorationDto) => {
      const { data } = await api.decoration().createDecoration(entity);
      return data;
    }
  ),

  update: createAsyncThunk(
    'mapDecoration/update',
    async ({
      id,
      entity,
    }: {
      id: string | number;
      entity: Partial<MapDecoration>;
    }) => {
      const { data } = await api.decoration().updateDecoration(id, entity);
      return data;
    }
  ),

  delete: createAsyncThunk(
    'mapDecoration/delete',
    async (id: string | number) => {
      const { data } = await api.decoration().deleteDecoration(id);
      return data;
    }
  ),
};

const adapter = createEntityAdapter<MapDecoration>();

const slice = createSlice({
  name: 'mapDecoration',
  initialState: adapter.getInitialState({ status: 'idle' } as {
    status: 'loading' | 'idle';
    lastSyncTimestamp?: number;
    error?: SerializedError;
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(mapDecorationThunkActions.fetchAll.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        mapDecorationThunkActions.fetchAll.fulfilled,
        (state, { payload }) => {
          adapter.setAll(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(
        mapDecorationThunkActions.fetchAll.rejected,
        (state, { error }) => {
          state.error = error;

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )

      .addCase(mapDecorationThunkActions.fetchOne.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        mapDecorationThunkActions.fetchOne.fulfilled,
        (state, { payload }) => {
          adapter.upsertOne(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(
        mapDecorationThunkActions.fetchOne.rejected,
        (state, { error }) => {
          state.error = error;

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )

      .addCase(mapDecorationThunkActions.create.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        mapDecorationThunkActions.create.fulfilled,
        (state, { payload }) => {
          adapter.addOne(state, payload);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(
        mapDecorationThunkActions.create.rejected,
        (state, { error }) => {
          state.error = error;

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )

      .addCase(mapDecorationThunkActions.update.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        mapDecorationThunkActions.update.fulfilled,
        (state, { payload }) => {
          adapter.updateOne(state, {
            id: payload.id,
            changes: payload,
          });

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(
        mapDecorationThunkActions.update.rejected,
        (state, { error }) => {
          state.error = error;

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )

      .addCase(mapDecorationThunkActions.delete.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(
        mapDecorationThunkActions.delete.fulfilled,
        (state, { payload }) => {
          adapter.removeOne(state, payload.id);

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      )
      .addCase(
        mapDecorationThunkActions.delete.rejected,
        (state, { error }) => {
          state.error = error;

          state.status = 'idle';
          state.lastSyncTimestamp = Date.now();
        }
      );
  },
});

export default slice.reducer;
export const categoryActions = slice.actions;
