import { ACTION_TYPES } from '../actions/languages';
import { Language } from '../store/language';

const initialState = {
  list: [] as Language[],
  selectedLanguage: localStorage.getItem('selectedLanguage'),
};

export const language = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LANGUAGES:
      return {
        ...state,
        list: [...(action.payload as Language[])],
      };
    case ACTION_TYPES.SELECT_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    // case ACTION_TYPES.FETCH_LANGUAGE:
    //   return {
    //     ...state,
    //     language: action.payload,
    //   };
    case ACTION_TYPES.CREATE_LANGUAGE:
      return {
        ...state,
        list: [...state.list, action.payload as Language],
      };
    case ACTION_TYPES.UPDATE_LANGUAGE:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id == action.payload.id ? (action.payload as Language) : x
        ),
      };
    case ACTION_TYPES.DELETE_LANGUAGE:
      return {
        ...state,
        list: state.list.filter((x) => x.id != action.payload),
      };
    default:
      return state;
  }
};
