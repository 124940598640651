import api from './api';

export const ACTION_TYPES = {
  SET_STYLE: 'SET_STYLE',
  SET_CHARGING_STATIONS: 'SET_CHARGING_STATIONS',
  TOGGLE_STATIONS: 'TOGGLE_STATIONS',
  CHANGE_MARKER_SIZE: 'CHANGE_MARKER_SIZE',
  SET_RENDERED_OBJECTS: '[MAP] SET_RENDERED_OBJECTS',
  ADD_LAYER: 'ADD_LAYER',
  REMOVE_LAYER: 'REMOVE_LAYER',
  SET_MAP: 'SET_MAP',
  SET_SOURCE: 'SET_SOURCE',
  SET_DEFAULT_COLORS: 'SET_DEFAULT_COLORS',
  RESET_STYLE: 'RESET_STYLE',
  CHANGE_LATLNG: 'CHANGE_LATLNG',
  TOGGLE_WELCOME: 'TOGGLE_WELCOME',
  TOGGLE_DISCLAMER: 'TOGGLE_DISCLAMER',
  TOGGLE_MY_ROUTE_TUTORIAL: 'TOGGLE_MY_ROUTE_TUTORIAL',
  SET_SCREEN_INFO: 'SET_SCREEN_INFO',
};

export const setStyle = (style) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_STYLE,
    payload: style,
  });
};
export const setDefaultColors = (colors) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_COLORS,
    payload: colors,
  });
};

export const setSource = (name, source) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SOURCE,
    payload: { name: name, data: source },
  });
};
export const addLayer = (layer) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ADD_LAYER,
    payload: layer,
  });
};

export const changeClickedLatLng = (latLng) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.CHANGE_LATLNG,
    payload: latLng,
  });
};

export const toggleWelcome = (action) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.TOGGLE_WELCOME,
    payload: action,
  });
};

export const toggleDisclamer= (action) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.TOGGLE_DISCLAMER,
    payload: action,
  });
};

export const toggleMyRouteTutorial = (action) => (dispatch) => {
  if (!localStorage.getItem('tutorialWasOpened') || !action) {
    dispatch({
      type: ACTION_TYPES.TOGGLE_MY_ROUTE_TUTORIAL,
      payload: action,
    });
    localStorage.setItem('tutorialWasOpened', true);
  }
};

/**
 * @param {number[]} objectIds
 * @param {import('../reducers/mapObject').MapObject[]} allObjects
 */
export const setRenderedObjects = (objectIds, allObjects) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_RENDERED_OBJECTS,
    payload: { objectIds, allObjects },
  });
};

export const setScreenInfo = (screenInfo) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SCREEN_INFO,
    payload: screenInfo,
  });
};
