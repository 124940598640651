import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '.';
import { MapObject } from '../reducers/mapObject';
import { Route } from './route';
import { getDistanceFromLatLonInKm } from '../utils/getDistanceFromCoordinates';
import { MapActionsEnum } from 'views/Map/MapActions/MapActionsEnum';

// All of the thunk actions can be rewritten as regular actions.
// This was created with different requirements in mind.

export const mapFilterThunkActions = {
  setAllCategoriesActive: createAsyncThunk(
    'map/filter/toggleAllCategoriesActive',
    async (_, { dispatch, getState }) => {
      const state = getState() as AppState;
      dispatch(
        mapFilterActions.setActiveCategories(
          state.category.ids.map((i) => Number(i))
        )
      );

      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  setAllCategoriesInactive: createAsyncThunk(
    'map/filter/toggleAllCategoriesInactive',
    async (_, { dispatch, getState }) => {
      dispatch(mapFilterActions.setActiveCategories([]));
      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  toggleCategory: createAsyncThunk(
    'map/filter/toggleCategory',
    async (categoryId: number, { dispatch, getState }) => {
      dispatch(mapFilterActions.toggleActiveCategory({ id: categoryId }));
      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  toggleMinigame: createAsyncThunk(
    'map/filter/setMinigameActive',
    async (isActive: boolean, { dispatch, getState }) => {
      dispatch(mapFilterActions.setMinigameActive(isActive));
      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  setActiveRoute: createAsyncThunk(
    'map/filter/setActiveRouteId',
    async (route: Route | undefined, { dispatch, getState }) => {
      // If route id is 0, or undefined, then the route was unselected.
      dispatch(mapFilterActions.setActiveRouteId({ id: route?.id }));

      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  toggleFavoritesFilter: createAsyncThunk(
    'map/filter/toggleFavoritesFilter',
    (_, { dispatch, getState }) => {
      dispatch(mapFilterActions.toggleFavoritesFilter());

      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  setActiveRenderDistance: createAsyncThunk(
    'map/filter/setActiveRenderDistance',
    async (distance: number, { dispatch, getState }) => {
      dispatch(mapFilterActions.setActiveRenderDistance(distance));
      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  updateRenderedObjects: createAsyncThunk(
    // Check which objects are in range to be rendered
    'map/filter/updateRenderedObjects',
    async (_, { dispatch, getState }) => {
      return updateRenderedObjects(getState() as AppState, dispatch);
    }
  ),
  setActiveMapAction: createAsyncThunk(
    'map/filter/setActiveMapAction',
    async (mapAction: MapActionsEnum, { dispatch }) => {
      dispatch(mapFilterActions.setActiveMapAction(mapAction));
    }
  ),
  renderObject: createAsyncThunk(
    'map/filter/setActiveMapAction',
    async (id: [number], { dispatch }) => {
      dispatch(mapFilterActions.setRenderedObjects(id));
    }
  ),
  toggleCycleways: createAsyncThunk(
    'map/filter/toggleCycleways',
    (isTrue: boolean | undefined = undefined, { dispatch, getState }) => {
      dispatch(mapFilterActions.toggleCycleways(isTrue));
    }
  ),
};

const slice = createSlice({
  name: 'map/filter',
  initialState: {
    minigameActive: false as boolean,

    renderedObjectIds: [] as number[],

    activeCategoryIds: {} as { [id: number]: boolean },
    activeRouteId: undefined as number | undefined,

    activeRenderDistance: 999999,

    activeMapAction: MapActionsEnum.none,

    displayCycleways: false
  },
  reducers: {
    setMinigameActive: (state, action: PayloadAction<boolean>) => {
      state.minigameActive = action.payload;
    },
    setRenderedObjects: (state, action: PayloadAction<number[]>) => {
      if (
        state.renderedObjectIds.length === action.payload.length &&
        state.renderedObjectIds.every((i) => action.payload[i])
      ) {
        return;
      }
      state.renderedObjectIds = action.payload;
    },
    setActiveCategories: (
      state,
      action: PayloadAction<{ [id: number]: boolean } | number[]>
    ) => {
      if (Array.isArray(action.payload)) {
        state.activeCategoryIds = action.payload.reduce((val, i) => {
          val[i] = true;
          return val;
        }, {} as { [id: number]: boolean });
      } else {
        state.activeCategoryIds = action.payload;
      }
    },
    toggleActiveCategory: (
      state,
      { payload }: PayloadAction<{ id: number }>
    ) => {
      state.activeCategoryIds[payload.id] = !state.activeCategoryIds[
        payload.id
      ];
    },
    setActiveRouteId: (
      state,
      { payload }: PayloadAction<{ id: number | undefined }>
    ) => {
      state.activeRouteId = payload.id;
    },
    toggleFavoritesFilter: (state) => {
      state.activeRouteId = 0;
    },
    setActiveRenderDistance: (state, action: PayloadAction<number>) => {
      state.activeRenderDistance = action.payload;
    },
    setActiveMapAction: (state, action: PayloadAction<MapActionsEnum>) => {
      state.activeMapAction = action.payload;
    },
    toggleCycleways: (state, action: PayloadAction<boolean | undefined>) => {
      if(typeof action.payload !== 'undefined')
        state.displayCycleways = action.payload;
      else
        state.displayCycleways = !state.displayCycleways;
    },
  },
});

export default slice.reducer;
const mapFilterActions = slice.actions;

const updateRenderedObjects = (state: AppState, dispatch: AppDispatch) => {
  const renderedObjects = getRenderedObjects(
    state.mapObject.list,
    state.mapFilter.activeCategoryIds,
    state.mapFilter.activeRenderDistance,
    state.mapFilter.activeRouteId,
    state.userData.favoriteObjectIds,
    state.mapFilter.minigameActive,
    state.userData.location,
    state.userData.displayHiddenItems
  );

  dispatch(
    mapFilterActions.setRenderedObjects(renderedObjects.map((o) => o.id))
  );

  return renderedObjects;
};

const getRenderedObjects = (
  allObjects: MapObject[],
  activeCategoryIds: { [id: number]: boolean },
  activeRenderDistance: number,
  activeRouteId: number | undefined,
  favoriteObjectIds: { [id: number]: boolean },
  minigameActive?: boolean,
  userLocation?: { lat: number; lng: number },
  displayHiddenItems?: boolean
) => {
  // If the minigame is active, nothing should be rendered.
  if (minigameActive) {
    return [];
  }

  let renderedObjects = allObjects;

  // Primary filter is the route. If person's favorites is active,
  if (activeRouteId === 0) {
    // Filter the list if the favorites is active.
    renderedObjects = renderedObjects.filter((o) => favoriteObjectIds[o.id]);
  } else if (activeRouteId) {
    // Filter the list if a route is currently active.
    renderedObjects = renderedObjects.filter(
      (o) => o.routeId === activeRouteId
    );
  } else {
    // In the event neither route nor favorites is selected, do not render anything.
    return [];
  }

  // Some items may be hidden. Make sure they are hidden.
  if (!displayHiddenItems) {
    renderedObjects = renderedObjects.filter((o) => o.isPublic);
  }

  // Filter the list by distance from user location
  if (userLocation) {
    renderedObjects = renderedObjects.filter((o) => {
      const coords = o.coordinates.split(',');
      return (
        getDistanceFromLatLonInKm(
          Number(coords[0]),
          Number(coords[1]),
          userLocation
        ) < activeRenderDistance
      );
    });
  }

  // Filter the list by currently selected categories.
  renderedObjects = renderedObjects.filter((o) =>
    o.categories.some((c) => activeCategoryIds[c.id])
  );

  return renderedObjects;
};
